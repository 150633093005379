import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Menu, MenuItem, IconButton, Grid, CircularProgress } from "@material-ui/core";
import * as Icons from "@material-ui/icons";
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
  menu: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px',
    borderRadius: '20px',
    maxWidth: '400px',
  },
  menuItem: {
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5px',
  },
  serviceIcon: {
    color: '#fff',
    backgroundColor: '#00b31f',
    borderRadius: '22px',
    width: '44px',
    height: '44px',
    padding: '5px',
  },
}));

const AppsButton = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [apps, setApps] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getMenu();
  }, []);

  const getMenu = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        app: 'admin',
        path: `/user/menu`,
      });
      if (response.code === 0) {
        setApps(response.apps);
      }
    } catch(e) {}
    setIsLoading(false);
  }

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLinkClick = (url) => {
    window.open(url, "_blank");
    handleClose();
  };

  return (
    <div>
      {
        isLoading ?
        <CircularProgress />
        :
        <IconButton onClick={handleButtonClick}>
          <Icons.Apps />
        </IconButton>
      }
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: classes.menu,
        }}
      >
        <Grid container spacing={2}>
          {
            apps.map((app, index) => {
              const AppIcon = Icons[app.icon];
              return (
                <Grid key={index} item xs={4}>
                  <MenuItem onClick={() => handleLinkClick(app.url)} className={classes.menuItem}>
                    <AppIcon className={classes.serviceIcon}/>
                    <span>{app.name}</span>
                  </MenuItem>
                </Grid>
              );
            })
          }
        </Grid>
      </Menu>
    </div>
  );
};

export default AppsButton;
