import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, Avatar, Paper, CircularProgress } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import Alert from '@material-ui/lab/Alert';
import * as Icons from '@material-ui/icons';
import { Auth } from 'aws-amplify';
import NeokodeButton from '../components/NeokodeButton';
import { Link as RouterLink } from 'react-router-dom';
import { invokeApig } from '../libs/awsLib';

const useStyles = makeStyles((theme) => ({
	welcome: {
		marginTop: '15px',
	},
	userCard: {
		backgroundColor: '#383838',
		color: '#fff',
		textAlign: 'center',
	},
	avatar: {
		backgroundColor: '#00b31f',
		width: theme.spacing(7),
		height: theme.spacing(7),
		margin: 'auto'
	},
	avatarSkeleton: {
		margin: '0 auto'
	},
	section: {
		margin: theme.spacing(3, 0),
		marginBottom: theme.spacing(10),
		width: 'inherit',
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: 'center',
	},
	gridMax: {
		[theme.breakpoints.up('md')]: {
			maxWidth: theme.breakpoints.values.lg,
		},
	},
	text: {
		marginBlockEnd: '16px',
		marginBlockStart: '16px',
	},
	button: {
		marginTop: theme.spacing(2),
	},
}));

function HomePage(props) {
	const { access, isAutenticate } = props.params;
	const classes = useStyles();
	const [userInfo, setUserInfo] = useState({});
	const [isLoadingUserInfo, setIsLoadingUserInfo] = useState(true);
	const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');

	useEffect(() => {
		getUserInfo();
	}, []);

	const getUserInfo = async () => {
		try {
			const userData = await Auth.currentAuthenticatedUser();
			setUserInfo(userData);
		} catch (error) {
			console.error('Error al obtener los datos del usuario: ', error);
		}
		setIsLoadingUserInfo(false);
	}

	return (
		<Grid container spacing={2} className={classes.section} justifyContent="center">
			<Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMax}>
				<Grid container spacing={2}>

					<Grid item xs={12} sm={12} md={12} lg={12}>
						<Card className={classes.userCard}>
							<CardContent>
								{
									isLoadingUserInfo ?
										<div>
											<Skeleton variant="circle" width={56} height={56} className={classes.avatarSkeleton}/>
											<Skeleton variant="text" height={47} />
											<Skeleton variant="text" height={32} />
										</div>
										:
										(
											(userInfo && userInfo.attributes) &&
											<>
												<Avatar className={classes.avatar}>
													<Icons.AccountCircle />
												</Avatar>
												<Typography variant="h5" className={classes.welcome}>Bienvenido, <b>{userInfo.attributes.name} {userInfo.attributes.family_name}</b></Typography>
												<Typography variant="h6">{userInfo.attributes.email}</Typography>
											</>
										)
								}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12} sm={12} md={12} lg={12} className={classes.section2}>
						<Card className={classes.userCard2}>
							<CardContent>
								<Typography variant="h4" className={classes.welcome}><b>Web App</b></Typography>
								<Typography className={classes.text}>
									En construcción
								</Typography>
							</CardContent>
						</Card>
					</Grid>

				</Grid>
			</Grid>
		</Grid>
	);
}
export default HomePage;