import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Card, CardContent, Typography, Button } from '@material-ui/core';;
import SiteForm from '../containers/SiteForm';

const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: '15px',
  },
  userCard: {
    backgroundColor: '#383838',
    color: '#fff',
    textAlign: 'center',
  },
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  gridMax: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  text: {
    marginBlockEnd: '16px',
    marginBlockStart: '16px',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

function SitePage(props) {
  const { access, isAutenticate } = props.params;
  const classes = useStyles();


  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMax}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} className={classes.section2}>
            <Card className={classes.userCard2}>
              <CardContent>
                <Typography variant="h4" className={classes.title}><b>Contratar Sitio</b></Typography>
                <SiteForm/>
             
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default SitePage;