import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, IconButton, Grid, Paper, TextField, Button,
  Dialog, DialogActions, DialogContent, DialogTitle,
  Snackbar,
  Tooltip,
  Chip,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { DataGrid, esES } from '@material-ui/data-grid';
import * as Icons from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import NoRowsOverlay from '../components/NoRowsOverlay';
import QuickSearchToolbar from '../components/QuickSearchToolbar';
import moment from 'moment';
import { invokeApig } from '../libs/awsLib';



const useStyles = makeStyles((theme) => ({
  section: {
    margin: theme.spacing(3, 0),
    marginBottom: theme.spacing(10),
    width: 'inherit',
  },
  paper: {
    padding: theme.spacing(2),
  },
  gridMaxMd: {
    [theme.breakpoints.up('md')]: {
      maxWidth: theme.breakpoints.values.lg,
    },
  },
  deleteButton: {
    backgroundColor: '#f44336',
    '&:hover': {
      backgroundColor: '#f44336',
    },
  },
  resetButton: {
    backgroundColor: '#2196f3',
    '&:hover': {
      backgroundColor: '#2196f3',
    },
  },
  textValidate: {
    margin: '15px 0',
  },
  configIcon: {
    color: '#383838',
  },
  editIcon: {
    color: '#2196f3',
  },
  lockIcon: {
    color: '#ff9800',
  },
  deleteIcon: {
    color: '#f44336',
  },
  buttonsDialogContainer: {
    padding: '10px 25px',
  },
}));

function escapeRegExp(value) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

function SitesPage(props) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [updateSiteData, setUpdateSiteData] = useState({
    name: '',
    description: ''
  });
  
  const [siteId, setSiteId] = useState('');
  const [isDeleteInstance, setIsDeleteInstance] = useState(false);
  const [isUpdateWebsite, setIsUpdateWebsite] = useState(false);
  const [textValidate, setTextValidate] = useState('');
  
  const [sites, setSites] = useState([]);
  const [rows, setRows] = useState(sites);
  const [searchText, setSearchText] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [messageToast, setMessageToast] = useState('');
  

  useEffect(() => {
    getSites();
  }, []);

  //Obtener sitios 
  const getSites = async () => {
    try {
      setIsLoading(true);
      setSites([]);
      setRows([]);
      setSearchText('');
      const response = await invokeApig({
        path: "/site/find",
        method: "POST",
      });
      if (response.statusCode === 200) {
        setSites(response.sites);
        setRows(response.sites);
      } else {
        setMessage('Error al obtener los sitios. Por favor contactar a Soporte.');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error in getAccess:', error);
      setMessage('Error al obtener los sitios . Intenta nuevamente o consulta a Soporte.');
      setIsError(true);
    }
    setIsLoading(false);
  }
  // eliminar sitios 
  const deleteSite = async (siteId) => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/site/${siteId}`, 
        method: "DELETE",
        body: {
          domain: siteId,
          
        }
      });

      if (response.statusCode === 200) {
        setMessageToast('Se ha eliminado el Sitio correctamente');
        setOpenToast(true);
        setOpenDialog(false);
        
        // Recargar la lista de sitios
        getSites();
      } else {
        // Manejar diferentes códigos de error
        setMessage(response.message || 'Error al eliminar el Sitio');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error in deleteSite:', error);
      setMessage('Ocurrió un error al eliminar el Sitio. Por favor intenta más tarde o comunícate con Soporte');
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const updateSite = async () => {
    try {
      setIsLoading(true);
      const response = await invokeApig({
        path: `/site`,
        method: "PUT",
        body: {
          ...updateSiteData,
          domain: siteId,
        }
      });
  
      if (response.Status === 0) {
        setMessageToast('Sitio actualizado correctamente');
        setOpenToast(true);
        setOpenUpdateDialog(false);
        getSites(); 
      } else {
        setMessage('Error al actualizar el Sitio');
        setIsError(true);
      }
    } catch (error) {
      console.error('Error in updateSite:', error);
      setMessage('Ocurrió un error al actualizar el Sitio');
      setIsError(true);
    }
    setIsLoading(false);
  };


  const requestSearch = (searchValue) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = sites.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row[field].toString());
      });
    });
    setRows(filteredRows);
  };


  const handleShowDeleteSite = (siteId) => {
    setIsError(false);
    setTextValidate('');
    setSiteId(siteId);
    setIsDeleteInstance(true);
    setOpenDialog(true);
  };
  //Update
  const handleShowUpdateSite = (siteId) => {
    const selectedSite = sites.find(site => site.domain === siteId);
    setUpdateSiteData({
      name: selectedSite.name,
      description: selectedSite.description || ''
    });
    setIsError(false);
    setSiteId(siteId);
    setIsUpdateWebsite(true);
    setOpenUpdateDialog(true);
  };

  const handleUpdateSiteChange = (e) => {
    const { name, value } = e.target;
    setUpdateSiteData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }
//dejar el de eliminar 
  const handleConfirmAction = () => {                                      
    if (isDeleteInstance && textValidate === 'eliminar Sitio') {
      deleteSite(siteId);
    }
  }

  const handleCloseToast = () => {
    setOpenToast(false);
  }

  const handleChange = (event) => {
    setTextValidate(event.target.value);
    setIsError(false);
  };
//revisar 
  const columns = [
    {
      field: 'creationDate',
      headerName: 'Fecha',
      flex: 1,
      filterable: false ,
      renderCell: (params) => (
        moment(params.value).format("DD-MM-YYYY HH:mm")
      )
    },
    {
      field: 'domain',
      headerName: 'Dominio',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nombre',
      flex: 1
    },
    {
      field: 'status',
      headerName: 'Estado',
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const state = params.value;
        return (
          /**
          <Chip label={state.name} icon={<Icons.Check style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: state.color}} />  
            */
          <Chip label={state} icon={<Icons.Check style={{color: '#fff'}}/>} style={{color: '#fff', backgroundColor: '#0F0'}} />          
        )
      }
    },
    //botones de accciones  
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      renderCell: (params) => (
        <>
          <Tooltip title="Configurar Sitio" enterDelay={500} leaveDelay={200}>
            <IconButton component={RouterLink} to={`/site/config/${params.id}`} className={classes.configIcon}>
              <Icons.Settings />
            </IconButton>
          </Tooltip>
          <Tooltip title="Editar Sitio" enterDelay={500} leaveDelay={200}>
            <IconButton onClick={() => handleShowUpdateSite(params.id)} className={classes.editIcon}>
              <Icons.Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eliminar Sitio" enterDelay={500} leaveDelay={200}>
            <IconButton onClick={() => handleShowDeleteSite(params.id)} className={classes.deleteIcon}>
              <Icons.Delete />
            </IconButton>
          </Tooltip>
        </>
      ),
    }
  ];

  const showConfirmDialog = () => {
    return (
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <p>{ isDeleteInstance && 'Se va a eliminar el sitio y ya no tendrá acceso. Esta acción no se puede reversar.' }</p>
            <p><b>¿Estás seguro que quieres continuar con la acción?</b></p>
          </Alert>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Validación"
                helperText={
                  isDeleteInstance ?
                  "Para eliminar el sitio por favor ingresa el texto 'eliminar sitio'"
                  :
                  "Para reiniciar el ERP por favor ingresa el texto 'reiniciar ERP'"
                }
                name="textValidate"
                value={textValidate}
                onChange={handleChange}
                required
                fullWidth
                className={classes.textValidate}
              />
            </Grid>
          </Grid>
          {
            isError &&
            <Alert severity="error">
              {message}
            </Alert>
          }
        </DialogContent>
        <DialogActions className={classes.buttonsDialogContainer}>
          <Button
            type="button"
            variant="contained"
            className={classes.button}
            onClick={handleCloseDialog}
            disabled={isLoading}
          >
            No, cerra esta ventana
          </Button>
          <Button
            type="button"
            color={isDeleteInstance ? "secondary" : "primary"}
            variant="contained"
            className={isDeleteInstance ? classes.deleteButton : classes.resetButton}
            onClick={handleConfirmAction}
            disabled={isLoading || (isDeleteInstance && textValidate !== 'eliminar ERP')}
          >
            Sí, confirmo
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const showUpdateDialog = () => {
    return (
      <Dialog 
        open={openUpdateDialog} 
        onClose={() => setOpenUpdateDialog(false)} 
        aria-labelledby="update-dialog-title"
      >
        <DialogTitle id="update-dialog-title">Actualizar Sitio</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="name"
                label="Nombre del Sitio"
                fullWidth
                value={updateSiteData.name}
                onChange={handleUpdateSiteChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Descripción"
                fullWidth
                multiline
                rows={4}
                value={updateSiteData.description}
                onChange={handleUpdateSiteChange}
              />
            </Grid>
          </Grid>
          {isError && (
            <Alert severity="error" style={{ marginTop: 15 }}>
              {message}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenUpdateDialog(false)} 
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button 
            color="primary" 
            variant="contained"
            onClick={updateSite}
            disabled={isLoading}
          >
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
    );
  };




  return (
    <Grid container spacing={2} className={classes.section} justifyContent="center">
      <Grid item xs={12} sm={12} md={12} lg={12} className={classes.gridMaxMd}>
        <Paper className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            Administración de Sitios 
          </Typography>

          <div style={{ height: '430px', width: '100%' }}>
            <DataGrid
              getRowId={(row) => row.domain}
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              loading={isLoading}
              components={{
                NoRowsOverlay: NoRowsOverlay,
                Toolbar: QuickSearchToolbar
              }}
              componentsProps={{
                toolbar: {
                  value: searchText,
                  onChange: (event) => requestSearch(event.target.value),
                  clearSearch: () => requestSearch(''),
                  onRefresh: () => getSites(),
                  addButtonUrl: '/sites/form',
                  addButtonText: 'Contratar Sitio '
                },
              }}
              localeText={esES.props.MuiDataGrid.localeText}
            />
          </div>
          { isDeleteInstance && showConfirmDialog()}
          {isUpdateWebsite && showUpdateDialog()}
        </Paper>
      </Grid>
      <Snackbar open={openToast} autoHideDuration={6000} onClose={handleCloseToast}>
        <Alert onClose={handleCloseToast} severity="success">
          {messageToast}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default SitesPage;
