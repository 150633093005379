import React, { useState, useEffect } from 'react';
import {
  Stepper, Step, StepLabel, StepContent, Button, Paper,
  FormControl, InputLabel, Select, Typography, Grid,
  CircularProgress, MenuItem, TextField, IconButton,
  Divider,
} from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { invokeApig } from '../libs/awsLib';
import NeokodeButton from '../components/NeokodeButton';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  stepTitle: {
    marginBottom: '30px',
  },
  stepIcon: {
    '&.MuiStepIcon-active': {
      color: '#00b31f',
    },
    '&.MuiStepIcon-completed': {
      color: '#00b31f',
    },
  },
  text: {
    margin: 'revert',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
}));

function SiteForm(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState('');
  const [domains, setDomains] = useState([]);
  const [isLoadingDomains, setIsLoadingDomains] = useState(true);
  const [isErrorDomains, setIsErrorDomains] = useState(false);
  const [cms, setCms] = useState({
    domain: '',
    name: '',
    description: '',
  });

  useEffect(() => {
    findDomains();
  }, []);

  const findDomains = async() => {
    try {
      setIsLoadingDomains(true);
      setIsErrorDomains(false);
      setDomains([]);
      const response = await invokeApig({
        app: "domain",
        path: "/domain/find",
        method: "POST",
      });
      if (response.status === 'OK') {
        setDomains(response.result);
      } else {
        setIsErrorDomains(true);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsErrorDomains(true);
    }
    setIsLoadingDomains(false);
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCms({ ...cms, [name]: value });
    setIsError(false);
  };

  const createCms = async () => {
    let isSuccess = false;
    try {
      setIsLoading(true);
      setIsError(false);
      const response = await invokeApig({
        path: "/site",
        method: "POST",
        body: cms
      });
      if (response.Status === 0) {
        isSuccess = true;
      } else {
        setMessage('No hemos podido crear el sitio, por favor intenta más tarde o contacta a Soporte');
        setIsError(true);
      }
    } catch (error) {
      setMessage('Ha ocurrido un error al crear el sitio, por favor intenta más tarde o contacta a Soporte');
      setIsError(true);
    }
    setIsLoading(false);
    return isSuccess;
  };

  const handleGoBack = (event) => {
    event.preventDefault();
    if (props.onFinish) {
      props.onFinish(false);
    } else {
      props.history.push('/cms');
    }
  }

  const handleNext = async () => {
    let isValid = true;
    switch(activeStep) {
      case 0: {
        break;
      }
      case 1: {
        if (!cms.domain) {
          setMessage('Debes seleccionar un dominio');
          isValid = false;
        }
        if (!cms.name) {
          setMessage('Debes ingresar un nombre para el sitio');
          isValid = false;
        }
        if (!cms.description) {
          setMessage('Debes ingresar una descripción para el sitio');
          isValid = false;
        }
        break;
      }
      case 2: {
        if (!await createCms()) {
          isValid = false;
        }
      }
    }
    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsError(!isValid);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepDescription = () => (
    <Alert severity="info">
      <AlertTitle>Crea tu propio sitio personalizado</AlertTitle>
      <Typography className={classes.text}>
        Con nuestro CMS, puedes gestionar fácilmente el contenido de tu sitio web y mantenerlo actualizado.
      </Typography>
      <Typography className={classes.text}>
        En los siguientes pasos configuraremos tu sitio para que puedas comenzar a utilizarlo de inmediato.
      </Typography>
    </Alert>
  );

  const getStepDetails = () => (
    <Paper className={classes.paper}>
      <Typography component="h4" className={classes.stepTitle}>
        Configuración de tu CMS
      </Typography>
      <FormControl className={classes.formControl}>
        <InputLabel id="domain-label">Dominio</InputLabel>
        <Select
          labelId="domain-label"
          id="domain"
          name="domain"
          value={cms.domain}
          onChange={handleChange}
          disabled={isLoadingDomains}
        >
          {domains.map((domain, index) => (
            <MenuItem key={index} value={domain.name}>
              {domain.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {isLoadingDomains && <CircularProgress />}
      <TextField
        className={classes.textField}
        label="Nombre del CMS"
        name="name"
        value={cms.name}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        label="Descripción"
        name="description"
        value={cms.description}
        onChange={handleChange}
        variant="outlined"
        multiline
        rows={4}
      />
    </Paper>
  );

  const getStepConfirm = () => (
    <Paper className={classes.paper}>
      <Typography component="h4" className={classes.stepTitle}>
        Confirma los datos de tu sitio
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={3}><b>Dominio:</b></Grid>
            <Grid item xs={9}>{cms.domain}</Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={3}><b>Nombre:</b></Grid>
            <Grid item xs={9}>{cms.name}</Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item xs={3}><b>Descripción:</b></Grid>
            <Grid item xs={9}>{cms.description}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );

  const getStepSuccess = () => (
    <Paper className={classes.paper}>
      <Typography component="h4" className={classes.stepTitle}>
        Sitio creado exitosamente
      </Typography>
      <Alert severity="success">
        <AlertTitle>¡Felicitaciones!</AlertTitle>
        <Typography>
          Tu sitio ha sido creado correctamente. Pronto recibirás un correo con las credenciales de acceso.
        </Typography>
      </Alert>
    </Paper>
  );

  const steps = [
    { label: 'Bienvenida', content: getStepDescription() },
    { label: 'Detalles del sitio', content: getStepDetails() },
    { label: 'Confirmación', content: getStepConfirm() },
    { label: 'Completado', content: getStepSuccess() },
  ];

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel StepIconProps={{ classes: { root: classes.stepIcon } }}>
              {step.label}
            </StepLabel>
            <StepContent>
              {step.content}
              {isError && (
                <Alert severity="error">
                  {message}
                </Alert>
              )}
              <div className={classes.actionsContainer}>
                {activeStep === steps.length - 1 ? (
                  <Button
                    variant="contained"
                    onClick={handleGoBack}
                    className={classes.button}
                  >
                    Ir a la lista de sitios 
                  </Button>
                ) : (
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Atrás
                    </Button>
                    <NeokodeButton
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={isLoading}
                    >
                      {activeStep === steps.length - 2 ? (
                        isLoading ? <CircularProgress size={24} /> : 'Crear sitio'
                      ) : (
                        'Siguiente'
                      )}
                    </NeokodeButton>
                  </div>
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

export default SiteForm;